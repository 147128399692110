import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts'

const SalesView = () => {

    const salesData = [
        { name: "Jan", sales: 4200 },
        { name: "Fev", sales: 3800 },
        { name: "Mars", sales: 5100 },
        { name: "Avr", sales: 4600 },
        { name: "Mai", sales: 5400 },
        { name: "Jui", sales: 7200 },
        { name: "Jul", sales: 6100 },
        { name: "Aou", sales: 5900 },
        { name: "Sep", sales: 6800 },
        { name: "Oct", sales: 6300 },
        { name: "Nov", sales: 7300 },
        { name: "Dec", sales: 7500 }
    ]

    const CustomLegend = () => {
        return (
            <div style={{ display: 'flex', alignItems: 'center', fontSize: '18px', color: '#00152b', marginTop: "2em", justifyContent: "center" }}>
                <div
                    style={{
                        width: '12px',
                        height: '12px',
                        backgroundColor: '#a90402',
                        marginRight: '8px',
                    }}
                ></div>
                <span>Ventes Mensuelles Année 2024</span>
            </div>
        );
    };

    return (
        <ResponsiveContainer width={"100%"} height={"100%"}>
            <LineChart data={salesData}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey={"name"} stroke='#00152b' />
                <YAxis stroke='#00152b' />
                <Tooltip
                    cursor={{
                        stroke: "#a90402"
                    }}
                />
                <Legend content={<CustomLegend />} />
                <Line
                    type='monotone'
                    dataKey='sales'
                    stroke='#a90402'
                    strokeWidth={2}
                    dot={{ fill: "", strokeWidth: 2, r: 6 }}
                    activeDot={{ r: 8, strokeWidth: 2 }}
                />
            </LineChart>
        </ResponsiveContainer>
    );
};

export default SalesView;