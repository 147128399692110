import React from 'react';
import DashbordCard from '../../components/DashbordCard';
import { FaUserFriends, FaArchive, FaIndent } from "react-icons/fa";
import { GiProgression } from "react-icons/gi";
import SalesView from '../../components/SalesView';

const Dashbord = () => {

    const adminCards = [
        {
            title: "commandes",
            number: 1000,
            icon: <FaIndent size={40} style={{ color: "#a90402" }} />

        },
        {
            title: "utilisateurs",
            number: 400,
            icon: <FaUserFriends size={40} style={{ color: "#a90402" }} />

        },
        {
            title: "ventes",
            number: 40,
            icon: <GiProgression size={40} style={{ color: "#a90402" }} />
        },
        {
            title: "Produits",
            number: 261,
            icon: <FaArchive size={40} style={{ color: "#a90402" }} />
        }
    ]


    return (
        <div className='admin-dashbord'>
            <h1>Debouches Frères</h1>
            <section className='admin-dashbord__cards'>
                {adminCards.map((adminCard, index) => (<DashbordCard adminCard={adminCard} key={`dashbord-card--${index}`} />))}
            </section>
            <section className='admin-dashbord__view'>
                <div className='admin-dashbord__view--orders'>
                    <h2>Commandes Recentes</h2>
                    <table>
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Nom</th>
                                <th>Montant</th>
                                <th>Date de recupération</th>
                                <th>Option</th>
                            </tr>
                        </thead>
                        <tbody >
                            <tr>
                                <td width="20%">07/12/2024</td>
                                <td width="25%">Bailo Sow</td>
                                <td width="10%">100€</td>
                                <td width="30%">07/12/2024 - 12:14:15</td>
                                <td width="15%">Voir</td>
                            </tr>
                        </tbody>
                    </table>

                </div>

                <div className='admin-dashbord__view--salechart'>
                    <SalesView />
                </div>
            </section>
        </div>
    );
};

export default Dashbord;