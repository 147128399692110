import React from 'react';

const DashbordCard = ({ adminCard }) => {

    return (
        <div className="admin-dashbord__cards--card">

            <h1 className='number'>{adminCard?.number}</h1>
            <p className='title'>{adminCard?.title}</p>

            <div className='icon'>
                <p>{adminCard?.icon}</p>
            </div>
        </div>
    );
};

export default DashbordCard;